// src/utils/initializeUser.ts
import {setError, User} from '../store/userSlice';
import { AppDispatch } from '../store';
import {setAccounts, TeslaAccount} from "../store/teslaAccountsSlice";

export const initializeUserFromMetaTag = (dispatch: AppDispatch) => {
    const userMetaTag = document.querySelector('meta[name="current_user"]');
    if (userMetaTag) {
        try {
            const content = userMetaTag.getAttribute('content');
            if (content) {
                // Decode HTML entities and parse JSON
                const decodedContent = decodeHTMLEntities(content);
                const userData: User = JSON.parse(decodedContent);

                // TODO: maybe use this for later...
            }
        } catch (error) {
            console.error('Failed to parse user data:', error);
        }
    }
};

export const initializeTeslaAccountsFromMetaTag = (dispatch: AppDispatch) => {
    const accountsMetaElement = document.querySelector('meta[name="tesla-accounts"]');
    if (accountsMetaElement) {
        try {
            const accountsData: TeslaAccount[] = JSON.parse(accountsMetaElement.getAttribute('content') || '[]');
            dispatch(setAccounts(accountsData));
        } catch (error) {
            console.error('Failed to parse Tesla accounts data:', error);
            dispatch(setError('Failed to load Tesla accounts'));
        }
    }
};


// Helper function to decode HTML entities
function decodeHTMLEntities(text: string): string {
    const textArea = document.createElement('textarea');
    textArea.innerHTML = text;
    return textArea.value;
}