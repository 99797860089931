
// userSlice.ts (updated)
import { ActionReducerMapBuilder, createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {SubscriptionStatus} from "./subscriptionSlice";

interface OtpRequest {
    email: string;
}

export interface TokenData {
    access_token: string;
    token_type: string;
    expires_in: number;
    refresh_token: string;
    created_at: number;
}
export interface UpdateAccountSettingsPayload {
    otp: string;
    email?: string;
    use_password_auth: boolean;
    password?: string;
}


export interface UserPayload {
    id: number;
    email: string;
    subscription_status: SubscriptionStatus;
    max_vehicles: number;
    max_devices: number;
    use_password_auth: boolean;
    created_at: string;
    updated_at: string;
}

export interface User {
    id: number;
    email: string;
    createdAt: string;
    updatedAt: string;
    usePasswordAuth: boolean;
    subscriptionStatus: SubscriptionStatus;
    maxVehicles: number;
    maxDevices: number;
}

interface UserState {
    currentUser: User | null;
    isAuthenticated: boolean;
    loading: boolean;
    error: string | null;
    tokens: TokenData | null;
    otpRequested: boolean;
    pendingEmail: string | null;
}

interface SignInRequest {
    email: string;
    password: string;
    authType: 'otp' | 'password';
}

const initialState: UserState = {
    currentUser: null,
    isAuthenticated: false,
    loading: false,
    error: null,
    tokens: null,
    otpRequested: false,
    pendingEmail: null,
};

export const mapUserPayloadToUser = (payload: UserPayload): User => ({
    id: payload.id,
    email: payload.email,
    subscriptionStatus: payload.subscription_status,
    usePasswordAuth: payload.use_password_auth,
    maxVehicles: payload.max_vehicles,
    maxDevices: payload.max_devices,
    createdAt: payload.created_at,
    updatedAt: payload.updated_at,
});


export const signInSuccess = createAction<User & { tokens: TokenData }>('user/signInSuccess');
export const signUpSuccess = createAction<User & { tokens: TokenData }>('user/signUpSuccess');

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        setError: (state, action: PayloadAction<string | null>) => {
            state.error = action.payload;
        },
        setTokens: (state, action: PayloadAction<TokenData | null>) => {
            state.tokens = action.payload;
            state.isAuthenticated = !!action.payload;
        },
        signInRequest: (state, action: PayloadAction<SignInRequest>) => {
            state.loading = true;
            state.error = null;
            state.pendingEmail = action.payload.email;
        },
        signInFailure: (state, action: PayloadAction<string>) => {
            state.loading = false;
            state.error = action.payload;
            state.isAuthenticated = false;
        },
        signUpFailure: (state, action: PayloadAction<string>) => {
            state.loading = false;
            state.error = action.payload;
            state.isAuthenticated = false;
        },
        signOutRequest: (state) => {
            state.loading = true;
        },
        signOutSuccess: (state) => {
            state.currentUser = null;
            state.isAuthenticated = false;
            state.loading = false;
            state.error = null;
            state.tokens = null;
            state.pendingEmail = null;
        },
        refreshTokenRequest: (state) => {
            state.loading = true;
        },
        refreshTokenSuccess: (state, action: PayloadAction<TokenData>) => {
            state.tokens = action.payload;
            state.loading = false;
            state.isAuthenticated = true;
        },
        refreshTokenFailure: (state, action: PayloadAction<string>) => {
            state.error = action.payload;
            state.loading = false;
            state.isAuthenticated = false;
            state.tokens = null;
        },
        otpRequest: (state, action: PayloadAction<OtpRequest>) => {
            state.loading = true;
            state.error = null;
            state.pendingEmail = action.payload.email;
        },
        otpRequestSuccess: (state) => {
            state.loading = false;
            state.otpRequested = true;
        },
        otpRequestFailure: (state, action: PayloadAction<string>) => {
            state.loading = false;
            state.error = action.payload;
            state.otpRequested = false;
        },
        resetOtpRequested: (state) => {
            state.otpRequested = false;
            state.pendingEmail = null;
        },
        updateAccountSettingsRequest: (state, action: PayloadAction<UpdateAccountSettingsPayload>) => {
            state.loading = true;
            state.error = null;
        },
        updateAccountSettingsSuccess: (state, action: PayloadAction<Omit<UpdateAccountSettingsPayload, 'otp' | 'password'>>) => {
            state.loading = false;
            if (state.currentUser) {
                if (action.payload.email) {
                    state.currentUser.email = action.payload.email;
                }
                state.currentUser.usePasswordAuth = action.payload.use_password_auth;
            }
        },
        updateAccountSettingsFailure: (state, action: PayloadAction<string>) => {
            state.loading = false;
            state.error = action.payload;
        },
        clearError: (state) => {
            state.error = null;
        },
        clearUserData: (state) => {
            // Reset all user-related state to initial values
            state.currentUser = null;
            state.isAuthenticated = false;
            state.loading = false;
            state.error = null;
            state.tokens = null;
            state.otpRequested = false;
            state.pendingEmail = null;
        },
        initializeApp: (state) => {
            state.loading = true;
        },
        initializeAppSuccess: (state) => {
            state.loading = false;
        },
        initializeAppFailure: (state, action: PayloadAction<string>) => {
            state.loading = false;
            state.error = action.payload;
            state.isAuthenticated = false;
            state.tokens = null;
        },
    },
    extraReducers: (builder: ActionReducerMapBuilder<UserState>) => {
        builder
            .addMatcher(
                (action) => action.type === signInSuccess.type || action.type === signUpSuccess.type,
                (state, action: PayloadAction<UserPayload & { tokens: TokenData }>) => {
                    state.loading = false;
                    state.error = null;
                    state.currentUser = mapUserPayloadToUser(action.payload);
                    state.tokens = action.payload.tokens;
                    state.isAuthenticated = true;
                }
            );
    },
});

export const {
    initializeApp,
    initializeAppSuccess,
    initializeAppFailure,
    clearUserData,
    setLoading,
    setError,
    setTokens,
    signInRequest,
    signInFailure,
    signUpFailure,
    signOutRequest,
    signOutSuccess,
    refreshTokenRequest,
    refreshTokenSuccess,
    refreshTokenFailure,
    otpRequest,
    otpRequestSuccess,
    otpRequestFailure,
    resetOtpRequested,
    updateAccountSettingsRequest,
    updateAccountSettingsSuccess,
    updateAccountSettingsFailure,
    clearError,
} = userSlice.actions;

export default userSlice.reducer;